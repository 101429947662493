
.site-header {
	background-color: $color-white;

	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	padding: $space-lg $space-md;

	text-align: center;

}

.site-name {
	@include visually-hidden;
}

.site-logo {
	display: block;
	width: 30rem;

	margin-bottom: $space-md;
}

.site-header-contact {
	width: 100%;

	.line {
		white-space: nowrap;
	}
}






@include breakpoint($bp-md) {
	.site-header {
		top: 0;
		margin: 0;
		position: fixed;
		width: $fixed-panel-width;
		height: 100vh;
		text-align: left;
	}

	.site-header-contact {

		max-width: 30rem;
	}
}


// Narrow or very low viewports
@include breakpoint(((max-width $bp-md), (max-height 500px))) {
	.site-header-contact {
		max-width: none;
		font-size: $font-size-xs;

		br {
			display: none;
		}

		p {
			margin: .5em 0 0;
		}
		.line:not(:last-of-type):after {
			content: '';
			display: inline-block;
			padding-left: .5em;
		}
	}
}

@include breakpoint(($bp-md) (max-height 500px)) {
	.site-header-contact {
	}
}



// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,200;1,400;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,500;1,500&family=Merriweather:ital@0;1&display=swap');

html,
body {
	text-rendering: optimizeLegibility;

}

html {
	font-size: $base-font-size / 16px * 100%;	
}

body {
	font-size: $font-size-body;
	font-family: $font-family-body;
	line-height: $line-height-body;
	font-weight: $font-weight-normal;
	color: $color-text-body;
}


h1,h2,h3,h4,h5,h6 {
	margin-bottom: .2em;
	font-family: $font-family-heading;
	font-weight: inherit;
	line-height: $line-height-heading;

	* + & {
		margin-top: 1.4em;
	}
}

h1 {
	font-size: $font-size-1;
	font-weight: $font-weight-heading;
	color: $color-text-heading;
}
h2 {
	font-size: $font-size-2;
	font-weight: $font-weight-heading;
	color: $color-text-heading;
}
h3 {
	font-size: $font-size-3;
	font-weight: $font-weight-heading;
	color: $color-text-heading;
}
h4 {
	font-size: $font-size-4;
	font-weight: $font-weight-heading;
	color: $color-text-heading;
}
h5 {
	font-size: $font-size-5;
}
h6 {
	font-size: $font-size-6;
}

.position {
	font-family: $font-family-heading;
	font-weight: $font-weight-heading;
	line-height: $line-height-heading;
	font-size: $font-size-4;
	margin-top: 0;
	color: $color-text-light;
}
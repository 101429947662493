

$color-white: #ffffff;
$color-black: #000000;

$color-gray-light: #f5f5f5;

$color-petrol: #004B65;
$color-petrol-light: #E3F3F9;

$color-text-body: rgba(0,0,0,.8);
$color-text-heading: rgba(0,0,0,.8);
$color-text-light: rgba(0,0,0,.5);


$font-stack-lato: 'Lato', sans-serif;
$font-stack-barlow: 'Barlow Condensed', sans-serif;
$font-stack-merriweather: 'Merriweather', serif;

$font-family-body: $font-stack-merriweather;
$font-family-heading: $font-stack-barlow;

$base-font-size: 10px;
$base-unit: 1rem;

$font-size-1: 4.2rem;
$font-size-2:	3.6rem;
$font-size-3:	2.8rem;
$font-size-4:	2.4rem;
$font-size-5: 2.0rem;
$font-size-6: 2.0rem;

$font-size-body: 1.8rem;
$font-size-xs: 1.3rem;

$line-height-body: 1.8;
$line-height-heading: 1.4;

$font-weight-normal: 400;
$font-weight-heading: 500;


$space-sm: $base-unit;
$space-md: $base-unit * 4;
$space-lg: $base-unit * 6;


$bp-sm: 60rem;
$bp-md: 90rem;
$bp-lg: 100rem;


$fixed-panel-width: 35vw;


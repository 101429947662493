/**
* Document
*
* Base document layout and setup.
*/
*,
*:before,
*:after {
	box-sizing: border-box;
}


html {
	-webkit-overflow-scrolling: touch;
}

body {
	background: $color-gray-light;
}


.main-content {
	margin: 0;
}

@include breakpoint($bp-md) {
	.main-content {

		margin-left: $fixed-panel-width;
		margin-right: 0;
		width: auto;	
	}
}
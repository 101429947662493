
.section {
	margin-top: $space-lg;
	margin-bottom: $space-lg;
	@include clearfix;
}

.section + .section {
	// margin-top: $space-lg;
}

.section-inner {
	@include container;
}

.section-heading {
	font-size: $font-size-1;

}

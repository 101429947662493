@mixin visually-hidden {
	position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.visually-hidden:not(:focus):not(:active) { 
  @include visually-hidden;
}

@mixin clearfix {
	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}
}
.clearfix {
	@include clearfix;
}